import { ChangeDetectorRef, Component, inject, Input, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ExceptionalConsignment, initialExceptionalConsignment, RailOrder, WagonInformation } from "../../../../../../models/rail-order-api";
import { RailOrderInternalService } from "src/app/order-management/service/rail-order-internal.service";
import { CodeNamePair } from "src/app/order-management/models/general-order";
import { ValidationMode } from "../../../../validators/validator-field.config";
import { WagonValidationService } from "../../../../service/wagon-validation-service.service";
import { BaseValidators } from "../../../../validators/base-validations";
import { WagonInformationUtils } from "../wagon-information-utils";

@Component({
  selector: 'app-authorization-list',
  templateUrl: './authorization-list.component.html',
  styleUrls: ['./authorization-list.component.scss']
})
export class AuthorizationListComponent implements OnInit {

  @Input() railOrder: RailOrder;
  @Input() formGroup: FormGroup;
  @Input() editMode: boolean;
  @Input() wagonInformation: WagonInformation;
  @Input() validationMode: ValidationMode;

  private railwayCompanyChange: Subject<string> = new Subject<string>(); // | to debounce input requests
  private wagonValidationService: WagonValidationService = inject(WagonValidationService);
  protected railwayCompanyAutocomplete: CodeNamePair[] = [];


  constructor(private fb: FormBuilder, private railOrderInternalService: RailOrderInternalService, private cd: ChangeDetectorRef) {
    this.registerForInputChanges();
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngAfterViewInit(): void {
    // Add a new line if authorizationList is empty
    if (this.authorizationList.length === 0) {
      this.addNewLine(null);
    }
    this.cd.detectChanges();
    this.wagonValidationService.validateSingleWagon(this.railOrder, this.wagonInformation, this.validationMode, this.formGroup);
  }

  private setupValidationOnChanges(): void {
    this.authorizationList.valueChanges.subscribe(() => {
      this.validateAuthorizationList();
    });
  }

  private validateAuthorizationList(): void {
    this.authorizationList.controls.forEach((itemGroup: FormGroup, index: number) => {
      this.validateSingleAuthorization(itemGroup);;
    });
  }

  private validateSingleAuthorization(itemGroup: FormGroup): void {
    const imCodeControl = itemGroup.get('imCode');
    const permissionNumberControl = itemGroup.get('permissionNumber');
    const imCode = imCodeControl?.value;
    const permissionNumber = permissionNumberControl?.value;


    imCodeControl?.setErrors(null);
    permissionNumberControl?.setErrors(null);
    
    if (!(imCode || permissionNumber)) {
      return;
    }
    
    const imCodeWithoutPermissionNumberError = BaseValidators.EiuEvuWithoutPermissionNumber(imCode, permissionNumber);

    if (imCodeWithoutPermissionNumberError) {
      imCodeControl?.setErrors({ hasImCodeWithoutPermissionNumber: true });
    }

    const PermissionNumberWithoutEiuEvuError = BaseValidators.PermissionNumberWithoutEiuEvu(imCode, permissionNumber);
    if (PermissionNumberWithoutEiuEvuError) {
      permissionNumberControl?.setErrors({ hasPermissionNumberWithoutImCode: true });
    }
     // Trigger change detection after validation
     this.cd.detectChanges();
  }
  
  private registerForInputChanges(): void {
    this.railwayCompanyChange.pipe(debounceTime(500)).subscribe((input: string) => {
      this.getRailwayCompanyAutocomplete(input);
    });
  }

  private initForm(): void {
    this.formGroup.addControl('authorizationList', this.fb.array([]));
    this.setupValidationOnChanges();
    this.setFormValues();
  }

  private setFormValues() {
    // Clear the authorizationList only if it exists
    if (this.authorizationList && this.authorizationList.length > 0) {
      this.authorizationList.clear();
    }

    // Populate the form with values from wagonInformation, or add a new line
    if (this.wagonInformation && this.wagonInformation.exceptionalConsignments?.length) {
      let isBaz = true;
      this.wagonInformation.exceptionalConsignments.forEach((item: ExceptionalConsignment) => {
        if (item.imCode == '2180') {
          if (!isBaz) {
            this.addNewLine(item);
          }
          isBaz = false;
        } else {
          this.addNewLine(item);
        }

      });
    } else {
      this.addNewLine(null);  // Add an empty line if no consignments are provided
    }
  }

  protected getFormValues(): ExceptionalConsignment[] {
    const formValues: ExceptionalConsignment[] = [];

    this.authorizationList.controls.forEach((group: FormGroup) => {
      const item: ExceptionalConsignment = {
        imCode: group.get('imCode')?.value,
        permissionNumber: group.get('permissionNumber')?.value
      };
      formValues.push(item);
    });

    return formValues;
  }

  protected get authorizationList(): FormArray {
    return this.formGroup?.get('authorizationList') as FormArray;
  }

  protected getControl(i: number, controlName: string): FormControl {
    const group = this.authorizationList.at(i) as FormGroup;
    return group.get(controlName) as FormControl;
  }

  protected getImCode(i: number): FormControl {
    return this.getControl(i, 'imCode');
  }

  protected getPermissionNumber(i: number): FormControl {
    return this.getControl(i, 'permissionNumber');
  }

  protected addNewLine(item?: ExceptionalConsignment | null): void {
    if (item == null || !item) {
      item = initialExceptionalConsignment();
      this.wagonInformation.exceptionalConsignments.push(item);
    }

    const itemGroup: FormGroup = this.fb.group({
      imCode: new FormControl(item.imCode),
      permissionNumber: new FormControl(item.permissionNumber)
    });

    this.authorizationList.push(itemGroup);
  }

  protected removeLine(idx: number): void {
    if (this.authorizationList.length > 1) {
      this.authorizationList.removeAt(idx);
      this.wagonInformation.exceptionalConsignments.splice(idx + 1, 1);
    } else if (this.authorizationList.length === 2) {
      this.authorizationList.controls[0].get('imCode').clearValidators();
      this.authorizationList.controls[0].get('imCode').setValue(null);

      this.authorizationList.controls[0].get('permissionNumber').clearValidators();
      this.authorizationList.controls[0].get('permissionNumber').setValue(null);

      this.wagonInformation.exceptionalConsignments.splice(1, 1);

    } else if (this.authorizationList.length === 1) {
      this.authorizationList.controls[0].get('imCode').clearValidators();
      this.authorizationList.controls[0].get('imCode').setValue(null);

      this.authorizationList.controls[0].get('permissionNumber').clearValidators();
      this.authorizationList.controls[0].get('permissionNumber').setValue(null);

      this.wagonInformation.exceptionalConsignments = [];
    }
  }

  protected autocompleteInputChanged(event: any, field: string): void {
    switch (field) {
      case 'im-code':
        this.railwayCompanyChange.next(event.target.value);
        break;
      default:
        break;
    }
  }

  private getRailwayCompanyAutocomplete(input: string): void {
    this.railOrderInternalService.getRailwayCompanies().subscribe((result: CodeNamePair[]) => {
      // Step 1: Filter out '2180' and then build a distinct list of valid companies
      const filteredResults = result.filter(company => company.code !== '2180');

      // Step 2: Remove duplicates based on 'company.code'
      const distinctResults = Array.from(
        new Map(filteredResults.map(company => [company.code, company])).values()
      );
      // Step 3: Sort the results by 'company.code' in ascending order
      const sortedResults = distinctResults.sort((a, b) => a.code.localeCompare(b.code));

      // Optionally, update the autocomplete with the sorted distinct results
      this.railwayCompanyAutocomplete = sortedResults;

    });
  }
  
  protected trackByFn(index: any, item: any): any {
    return index;
  }

  protected imCodeSelected(index: number) {
    const exceptionalConsignments = this.wagonInformation?.exceptionalConsignments;
    if (!exceptionalConsignments) return;
    const selectedImCode = this.getImCode(index)?.value;
    if (exceptionalConsignments[0]?.imCode === "2180") {
      // Ensure there is a next entry
      if (!exceptionalConsignments[index + 1]) {
        this.addNewLine(null);
      }
      exceptionalConsignments[index + 1].imCode = selectedImCode;
    } else {
      // Ensure there is a current entry
      if (!exceptionalConsignments[index]) {
        this.addNewLine(null);
      }
      exceptionalConsignments[index].imCode = selectedImCode;
    }
  }

  protected permissionNumberChanged(index: number) {
    const exceptionalConsignments = this.wagonInformation?.exceptionalConsignments;
    if (!exceptionalConsignments) return;

    const permissionNumber = this.getPermissionNumber(index)?.value;

    if (exceptionalConsignments[0]?.imCode === "2180") {
      // Ensure there is a next entry
      if (!exceptionalConsignments[index + 1]) {
        this.addNewLine(null);
      }
      exceptionalConsignments[index + 1].permissionNumber = permissionNumber;
    } else {
      // Ensure there is a current entry
      if (!exceptionalConsignments[index]) {
        this.addNewLine(null);
      }
      exceptionalConsignments[index].permissionNumber = permissionNumber;
    }
    WagonInformationUtils.checkForDuplicatePermissionNumbers(this.formGroup);
  }

  protected removeInvalidImCodeOnBlur(index: number): void {
    // Get the input control for imCode at the specified index
    const imCodeControl = this.getImCode(index);
  
    // Get the current value of the imCode input
    const enteredImCode = imCodeControl.value;
  
    // Step 1: Filter out the valid company codes from distinctResults
    const validCompanyCodes = this.railwayCompanyAutocomplete.map(company => company.code.toLowerCase());
  
    // Step 2: Check if the entered value is a valid company code
    const isValidImCode = validCompanyCodes.includes(enteredImCode.toLowerCase());
  
    // Step 3: If the input is invalid, clear the value and set an error
    if (!isValidImCode) {
      // Clear the input field
      imCodeControl.setValue(null);
  
      // Set an error to indicate the input is invalid
      imCodeControl.setErrors({ invalidCompanyCode: true });
  
      // Trigger validation again
      imCodeControl.updateValueAndValidity();
  
      // Optionally log for debugging
      console.log(`IM Code "${enteredImCode}" is invalid. Input has been cleared.`);
    }
  }
  
}