import { AfterViewInit, ChangeDetectorRef, Component, inject, Input } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms";
import { ExternalReference, RailOrder, WagonInformation } from "src/app/order-management/models/rail-order-api";
import { ValidationMode } from "../../../../validators/validator-field.config";
import { WagonValidationService } from "../../../../service/wagon-validation-service.service";

@Component({
  selector: 'app-customer-reference',
  templateUrl: './customer-reference.component.html',
  styleUrls: ['./customer-reference.component.scss']
})
export class CustomerReferenceComponent implements AfterViewInit {
  @Input() formGroup: FormGroup;
  @Input() railOrder: RailOrder;
  @Input() editMode: boolean;
  @Input() wagonInformation: WagonInformation;
  @Input() validationMode: ValidationMode;
  @Input() idx: number; // wagon

  private wagonValidationService: WagonValidationService = inject(WagonValidationService);

  ngAfterViewInit(): void {
    if (!this.customerReferenceFormArray) {
      this.initForm();
      this.createForm();
      this.cd.detectChanges();
      this.wagonValidationService.validateSingleWagon(this.railOrder, this.wagonInformation, this.validationMode, this.formGroup);
    }
  }

  constructor( private cd: ChangeDetectorRef) {
      
    }
  
  private createForm(): void {
    console.log(this.railOrder?.wagonInformation[this.idx]);
    if (this.railOrder?.wagonInformation[this.idx]?.externalReferences?.length > 0) {
      let crrExists = false;
      for (let item of this.railOrder.wagonInformation[this.idx].externalReferences) {
        console.log(item);
        if (item?.type === 'CRR') {
          crrExists = true;
          this.addLine(item);
        }
      } 
      if (crrExists === false) {
        this.addLine();  
      }
    } else {
      this.addLine();
    }
  }

  private initForm(): void {
    this.formGroup.addControl('customerReferenceFormArray', new FormArray([]));
  }

  protected addLine(reference?: ExternalReference): void {
    this.customerReferenceFormArray.push(this.createGroup(reference));
  }

  private createGroup(reference?: ExternalReference): FormGroup {
    const formGroup: FormGroup = new FormGroup({
      'identifier': new FormControl(reference?.identifier || '')
    });    
    return formGroup;
  }

  protected removeLine(idx: number): void {
    if (this.customerReferenceFormArray.length > 1) {
      let tmp: ExternalReference[] = [];
      let temp: AbstractControl<any, any>[] = [];
      let count_1 = 0;
      for (let item of this.customerReferenceFormArray.controls) {
        if (count_1 !== idx) {
          temp.push(item);
        }
        count_1++;        
      }
      this.customerReferenceFormArray.controls = temp;
      let counter = 0;
      for (let item of this.railOrder.wagonInformation[this.idx].externalReferences) {
        if (counter !== idx) {
          tmp.push(item);
        }
        counter++;
      }
      this.railOrder.wagonInformation[this.idx].externalReferences = tmp;
    } else if (this.customerReferenceFormArray.length === 1) {
      this.customerReferenceFormArray.controls[0].get('identifier').clearValidators();
      this.customerReferenceFormArray.controls[0].get('identifier').setValue(null);      
      this.railOrder.wagonInformation[this.idx].externalReferences = [];            
    }
  }

  get customerReferenceFormArray(): FormArray {
    return this.formGroup.get('customerReferenceFormArray') as FormArray;
  }
}
