// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field {
  padding-right: 0.6em;
}

.sub-section-header {
  font-weight: bold;
  margin-top: 0.9em;
}

.flex-row-footer {
  flex-shrink: 0;
  position: sticky;
  justify-content: space-between;
  z-index: 10;
  align-items: center;
  width: 100%;
  height: 70px;
}

.button-navigation {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

footer {
  padding: 10px 30px 0 30px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
