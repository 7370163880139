export enum SectionName {
  SECTION_CONSIGNEE_CONSIGNOR = 'SECTION_CONSIGNEE_CONSIGNOR',
  SECTION_PICKUP_DELIVERY = 'SECTION_PICKUP_DELIVERY',
  SECTION_TRANSPORT = 'SECTION_TRANSPORT',
  SECTION_WAGON_DATA = 'SECTION_WAGON_DATA',
  SECTION_COMMERCIAL = 'SECTION_COMMERCIAL',
  SECTION_SERVICE = 'SECTION_SERVICE',
  SECTION_SENDER_POLICY = 'SECTION_SENDER_POLICY'
};

export enum PartyType {
  PARTY_TYPE_UNLOADING_PARTY = 'PARTY_TYPE_UNLOADING_PARTY',
  PARTY_TYPE_LOADING_PARTY = 'PARTY_TYPE_LOADING_PARTY',
  PARTY_TYPE_CONSIGNOR = 'PARTY_TYPE_CONSIGNOR',
  PARTY_TYPE_CONSIGNEE = 'PARTY_TYPE_CONSIGNEE',
  PARTY_TYPE_CONTRACTUAL_CARRIER = 'PARTY_TYPE_CONTRACTUAL_CARRIER',
  PARTY_TYPE_FREIGHT_PAYER_CONSIGNOR = 'PARTY_TYPE_FREIGHT_PAYER_CONSIGNOR',
  PARTY_TYPE_FREIGHT_PAYER_CONSIGNEE = 'PARTY_TYPE_FREIGHT_PAYER_CONSIGNEE'
}

export enum OrderMode {
  CompleteOrder = 'CompleteOrder',
  Booking = 'Booking',
  Draft = 'Draft'
}