// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#section-button {
  display: flex;
  font-family: "DB Screen Sans", Helvetica, Arial, sans-serif !important;
  font-size: 14px !important;
  justify-content: flex-end; /* Align buttons to the right */
  align-items: center; /* Ensure buttons align properly */
  gap: 16px; /* Space between buttons */
  width: 95%;
}

.button-container {
  display: flex;
  font-family: "DB Screen Sans", Helvetica, Arial, sans-serif !important;
  font-size: 14px !important;
  justify-content: flex-end; /* Align buttons to the right */
  align-items: center; /* Ensure vertical alignment */
  gap: 16px; /* Space between buttons */
  width: 100%;
  padding-top: 16px;
}

.button-style {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px; /* Space between icon and text */
  font-size: 14px;
  font-weight: bold;
}

.button-style .icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  fill: white; /* Ensure icon color matches text */
}

.hint-container {
  display: flex;
  flex-direction: column; /* Ensures the text stays stacked */
  align-items: flex-start; /* Aligns content to the left */
  margin-left: 5%;
  margin-top: 20px;
}

.input-container {
  display: flex;
  flex-direction: column; /* Ensures the text stays stacked */
  align-items: flex-start; /* Aligns content to the left */
  margin-left: 5%;
  margin-top: 20px;
}

.import-text-container {
  border: gainsboro;
  width: 100%;
}

.import-text {
  resize: vertical;
  background-color: whitesmoke !important;
  margin: 10px 0px 0px 0px;
  border: gainsboro;
  width: 95%;
}

.table-container {
  flex-grow: 1;
  overflow-y: auto; /* Only body scrolls */
  margin: 0 5%;
  margin-top: 20px;
}

.fixed-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

.fixed-table th, .fixed-table td {
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Sticky Table Header */
.table-wrapper {
  overflow-y: auto;
  height: 500px; /* Set a max-height for the scrollable area */
}

.button-footer {
  position: sticky;
  bottom: 0;
  background: white;
  z-index: 10;
  padding: 10px;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
}

.tooltip {
  position: relative;
  display: inline-block;
  width: 30px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 400px;
  bottom: 50%; /* Aligns vertically */
  right: 110%; /* Moves tooltip to the left */
  white-space: pre-line;
  transform: translateY(80%); /* Centers it vertically */
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.items p {
  margin: 0px;
  padding: 10px;
}

.items p:hover {
  color: black;
  background: whitesmoke;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.error-message {
  color: red;
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
