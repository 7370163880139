import { Pipe, PipeTransform } from '@angular/core';
import { BorderAndCommercialLocationSummary } from 'src/app/order-management/models/rail-order-api';
import { CommercialLocationSummary } from 'src/app/order-management/models/general-order';

@Pipe({
  name: 'commercialLocationSummary'
})
export class CommercialLocationSummaryPipe implements PipeTransform {

  transform(commercialLocationSummary: CommercialLocationSummary | BorderAndCommercialLocationSummary): string {
    if(!commercialLocationSummary) {
      return null;
    }

    let code = commercialLocationSummary.locationCode;
    if(commercialLocationSummary.uicBorderCode) {
      code = `${commercialLocationSummary.uicBorderCode}`
    }

    return `(${code}) ${commercialLocationSummary.name}`
  }

}
