// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.grid-item {
  border: 2px solid grey;
  border-radius: 20px;
  padding: 20px;
  margin: 10px 10px 10px 0;
  cursor: pointer;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
}

.icon-block {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 15px;
  top: 30px;
}
.icon-block .icon {
  position: absolute;
  margin-top: 12px;
}

.highlight {
  border-color: red;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-header-sticky {
  flex-shrink: 0;
  position: sticky;
  top: 0;
  z-index: 10;
}

.modal-header-base, .modal-header-sticky {
  background: #f0f3f5;
  padding: 10px 30px 30px 30px;
  margin: 0;
  height: 90px;
}
.modal-header-base span, .modal-header-sticky span {
  font-weight: normal;
}
.modal-header-base h2, .modal-header-sticky h2 {
  margin-top: 16px;
}
.modal-header-base .close-icon, .modal-header-sticky .close-icon {
  position: absolute;
  top: 35px;
  right: 35px;
}
.modal-header-base .footer, .modal-header-sticky .footer {
  position: absolute;
  bottom: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
