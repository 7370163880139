import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, inject, Injectable } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogState } from "@angular/material/dialog";
import { ErrorDialogService } from "./service/api-error-dialog.service";
import { GenericErrorComponent } from "./generic-error/generic-error.component";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private modalWindowRef:MatDialogRef<GenericErrorComponent> = null;
    private apiErrorDialogService: ErrorDialogService = inject(ErrorDialogService);
    constructor(private dialog: MatDialog) { }

    handleError(error: any) {
        if (error instanceof HttpErrorResponse) {
            try {
                // if(this.modalWindowRef) {
                //     if(this.modalWindowRef.getState() == MatDialogState.OPEN) {
                //         this.modalWindowRef.close();
                //     }
                //     this.modalWindowRef = null;
                // }
                if(!this.modalWindowRef) {
                    this.modalWindowRef = this.apiErrorDialogService.openApiErrorDialog(error);
                    this.modalWindowRef.afterClosed().subscribe({
                        next: () => this.modalWindowRef = null
                    });
                }
            } catch (err) {
                console.error(err);
            }
        }
        console.error(error);
    }

    public closeErrorWindow() {
        if (this.modalWindowRef) { this.modalWindowRef.close(); }
    }
}