import { Component, Input, OnInit, AfterViewInit, inject, ChangeDetectorRef } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { initalLoadingTackles, LoadingTackles, RailOrder, WagonInformation } from "../../../../../../models/rail-order-api";
import { RailOrderInternalService } from "src/app/order-management/service/rail-order-internal.service";
import { CodeNamePair } from "src/app/order-management/models/general-order";
import { ValidationMode } from "../../../../validators/validator-field.config";
import { WagonValidationService } from "../../../../service/wagon-validation-service.service";
import { FormFieldService } from "../../../../service/form-field.service";
import { WagonInformationUtils } from "../wagon-information-utils";

@Component({
  selector: 'app-loading-tackles-list',
  templateUrl: './loading-tackles-list.component.html',
  styleUrls: ['./loading-tackles-list.component.scss']
})
export class LoadingTacklesListComponent implements OnInit, AfterViewInit {

  @Input() railOrder: RailOrder;
  @Input() formGroup: FormGroup;
  @Input() editMode: boolean;
  @Input() wagonInformation: WagonInformation;
  @Input() validationMode: ValidationMode;
 
  
  protected loadingTackles: CodeNamePair[] = []; 
  private wagonValidationService: WagonValidationService = inject(WagonValidationService);
  private formFieldService: FormFieldService = inject(FormFieldService);
  private hasAC :boolean;

  constructor(private fb: FormBuilder, private railOrderInternalService: RailOrderInternalService, private cd: ChangeDetectorRef) {
    this.createDropdownFields();
  }

  ngOnInit(): void {
    this.initForm();
    const templateNumber = this.railOrder?.templateNumber?.toString().trim();
    this.hasAC = !!templateNumber; // Ensures a boolean value
  }

  ngAfterViewInit(): void {
    this.addLinesToForm();
    this.cd.detectChanges(); 
    this.wagonValidationService.validateSingleWagon( this.railOrder, this.wagonInformation, this.validationMode, this.formGroup);
    this.setupValidationOnChanges();
  }

  private setupValidationOnChanges(): void {
    this.loadingTacklesList.valueChanges.subscribe(() => {
      this.validateLoadingTackles();
    });
  }
  
  private validateLoadingTackles(): void {
      this.loadingTacklesList.controls.forEach((itemGroup: FormGroup) => {
        this.validateSingleLoadingTackles(itemGroup);
      });
  
      this.cd.detectChanges();
    }

    private validateSingleLoadingTackles(loadingTacklesForm: FormGroup): void {
      const loadingTackleNumberControl = loadingTacklesForm.get('numberOfLoadingTackles');
      const loadingTackleTypeControl = loadingTacklesForm.get('type');
      const loadingTackleWeightControl = loadingTacklesForm.get('weight');
      const loadingTackleIdentifierControl = loadingTacklesForm.get('identifier');
      
      const loadingTackleNumber = loadingTackleNumberControl?.value || 0;
      const loadingTackleType = loadingTackleTypeControl?.value || null;
      const loadingTackleWeight = loadingTackleWeightControl?.value || 0;

      // Initialize the error object for each control
      let numberErrors: ValidationErrors = {};
      let typeErrors: ValidationErrors = {};
      let weightErrors: ValidationErrors = {};
    
      // Validate the number of loading tackles
      if (!loadingTackleType && (loadingTackleNumber || loadingTackleWeight)) {
        weightErrors = { ...weightErrors, typeRequired: true };
      }
    
      if (loadingTackleNumber && loadingTackleNumber > 99) {
        numberErrors = { ...numberErrors, numberTooLarge: true };
      }
    
      if (!loadingTackleNumber && (loadingTackleType || loadingTackleWeight)) {
        numberErrors = { ...numberErrors, numberRequired: true };
      }
      
      if (!loadingTackleType && (loadingTackleNumber || loadingTackleWeight)) {
        typeErrors = { ...typeErrors , typeRequired: true };
      }
      
      if (!loadingTackleWeight && (loadingTackleNumber || loadingTackleType)) {
        weightErrors = { ...weightErrors, weightRequired: true };
      }
    
      // Check the total weight of the wagon
      const tacklesWeight = this.calculateLoadingTacklesWeight();
      const goodWeight = WagonInformationUtils.getGoodsWeight(this.wagonInformation);      
      const totalWeight = tacklesWeight + goodWeight;

      if (totalWeight > 600000) {
        weightErrors = { ...weightErrors, wagonWeightTooHigh: true };
      }
    
      // Apply errors to the controls if there are any
      if (Object.keys(numberErrors).length > 0) {
        loadingTackleNumberControl?.setErrors(numberErrors);
      } else {
        loadingTackleNumberControl?.setErrors(null);
      }
    
      if (Object.keys(typeErrors).length > 0) {
        loadingTackleTypeControl?.setErrors(typeErrors);
      } else {
        loadingTackleTypeControl?.setErrors(null);
      }
    
      if (Object.keys(weightErrors).length > 0) {
        loadingTackleWeightControl?.setErrors(weightErrors);
      } else {
        loadingTackleWeightControl?.setErrors(null);
      }
    
      if (Object.keys(loadingTackleIdentifierControl?.errors || {}).length > 0) {
        loadingTackleIdentifierControl?.setErrors({ identifierRequired: true });
      } else {
        loadingTackleIdentifierControl?.setErrors(null);
      }
    }

    private calculateLoadingTacklesWeight(): number {
      let totalWeight = 0;
    
      // Iterate over the form array of loading tackles
      (this.formGroup.get('loadingTacklesList') as FormArray).controls.forEach((loadingTackleGroup: FormGroup) => {
        const weightControl = loadingTackleGroup.get('weight');
        const weight = Number(weightControl?.value) || 0;
        
        // Sum the weights of each loading tackle
        totalWeight += weight;
      });
    
      return totalWeight;
    }
    
  private createDropdownFields(): void {
    this.createLoadingTackes();
  }

  private createLoadingTackes(): void {
    this.railOrderInternalService.getLoadingTackles().subscribe((result: CodeNamePair[]) => {
      this.loadingTackles = result;
    }); 
  }

  private addLinesToForm() {
    if (this.wagonInformation.loadingTackles.length === 0) {
      this.addNewLine();
    } else if (this.wagonInformation.loadingTackles.length !== this.loadingTacklesList.length) {
      this.setFormValues();
    }
  }

  private initForm(): void {
    this.formGroup.addControl('loadingTacklesList', this.fb.array([]));    
  }

  private setFormValues() {
    this.loadingTacklesList.clear();
    if (this.wagonInformation.loadingTackles?.length) {
      this.wagonInformation.loadingTackles.forEach((item: LoadingTackles) => {
        this.addNewLine(item);
      });
    } else {
      this.addNewLine();
    }
    this.formFieldService.disableFields(this.formGroup, 'wagonDetails', this.railOrder, this.editMode, this.railOrder.orderId ? false : true);
  }
  
  protected getFormValues(): LoadingTackles[] {
    return this.loadingTacklesList.controls.map((group: FormGroup) => ({
      number: group.get('numberOfLoadingTackles')?.value,
      type: group.get('type')?.value,
      weight: group.get('weight')?.value,
      identifier: group.get('identifier')?.value
    }));
  }
  
  protected get loadingTacklesList(): FormArray {
    return this.formGroup.get('loadingTacklesList') as FormArray;
  }

  protected getControl(i: number, controlName: string): FormControl {
    return this.loadingTacklesList.at(i).get(controlName) as FormControl;
  }
  
  public getNumberOfLoadingTackles(i: number): FormControl {
    return this.getControl(i, 'numberOfLoadingTackles');
  }

  public getType(i: number): FormControl {
    return this.getControl(i, 'type');
  }

  public getIdentifier(i: number): FormControl {
    return this.getControl(i, 'identifier');
  }

  public getWeight(i: number): FormControl {
    return this.getControl(i, 'weight');
  }

  protected addNewLine(item?: LoadingTackles): void {
    const newItem = item || initalLoadingTackles();
  
    const itemGroup: FormGroup = this.fb.group({
      numberOfLoadingTackles: new FormControl(newItem.number, Validators.required),
      type: new FormControl(newItem.type, Validators.required),
      identifier: new FormControl(newItem.identifier, Validators.required),
      weight: new FormControl(newItem.weight, Validators.required)
    });
    this.loadingTacklesList.push(itemGroup);
    // Check if the FormGroup's controls are empty or undefined
    if (this.hasAC && this.isAllFieldsEmpty(itemGroup)) {
      this.disableAllFields(itemGroup);
    }
  
    if (!item) {
      this.wagonInformation.loadingTackles.push(newItem);
    }
    // Trigger change detection after disabling
    this.cd.detectChanges();
  }

  // Check if all controls in the FormGroup are empty, undefined, or null
  private isAllFieldsEmpty(itemGroup: FormGroup): boolean {
    let isEmpty = true;

    // Check for empty or undefined values for each control
    Object.keys(itemGroup.controls).forEach(key => {
        const control = itemGroup.get(key);

        if (control) {
            const value = control.value;

            // Check if the value is empty, undefined, or null for all fields except weight
            if (key !== 'weight' && (value !== '' && value !== undefined && value !== null)) {
                isEmpty = false;  // If any control has a non-empty value, set isEmpty to false
            }

            // Check for specific case where weight is 0
            if (key === 'weight' && value !== 0 && value !== '' && value !== undefined && value !== null) {
                isEmpty = false;  // Consider weight as "not empty" if it’s non-zero
            }
        }
    });

    return isEmpty;  // Return true if all fields are empty, undefined, or null
}

  // Disable all fields in the FormGroup
  private disableAllFields(itemGroup: FormGroup): void {
    Object.keys(itemGroup.controls).forEach(key => {
      const control = itemGroup.get(key);
      if (control) {
        control.disable();  
      }
    });
  
    // Ensure Angular reflects changes (mark for change detection)
    this.cd.markForCheck();
  }

  protected removeLine(idx: number): void {
    if (this.loadingTacklesList.length > 1) {
      this.loadingTacklesList.removeAt(idx);
      this.wagonInformation.loadingTackles.splice(idx, 1);
    } else if (this.loadingTacklesList.length === 1) {
      this.loadingTacklesList.controls[0].get('numberOfLoadingTackles').clearValidators();
      this.loadingTacklesList.controls[0].get('numberOfLoadingTackles').setValue(null);      

      this.loadingTacklesList.controls[0].get('type').clearValidators();
      this.loadingTacklesList.controls[0].get('type').setValue(null);

      this.loadingTacklesList.controls[0].get('identifier').clearValidators();
      this.loadingTacklesList.controls[0].get('identifier').setValue(null);

      this.loadingTacklesList.controls[0].get('weight').clearAsyncValidators();
      this.loadingTacklesList.controls[0].get('weight').setValue(null);
      this.wagonInformation.loadingTackles = [];      
    }
  }
  
  isRowModified(rowIndex: number): boolean {
    return this.loadingTacklesList.at(rowIndex)?.dirty || false;
  }

  protected onNumberOnlyKeydown(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete']; // Allow Backspace, Delete, Arrow keys
    const key = event.key;
    if (!/[\d]/.test(key) && !allowedKeys.includes(key)) {
      event.preventDefault();  // Prevent typing
    }
  }
/*
  isControlInvalid(rowIndex: number, controlName: string): boolean {
    const control = this.getControl(rowIndex, controlName);
    return this.isRowModified(rowIndex) && control.invalid && (control.touched || control.dirty);
  }
    */
}